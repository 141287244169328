import { createSelector } from "reselect";
import { AppState } from "@shared/interfaces/State.interface";

const selectDashboard = (state: AppState) => state.dashboard;

export const getApiStatusSelector = () =>
  createSelector(selectDashboard, (state) => state.apiStatus);
export const getCostExplorerSelector = () =>
  createSelector(selectDashboard, (state) => state.costExplorer);
export const getCloudWatchMetricsSelector = () =>
  createSelector(selectDashboard, (state) => state.cloudWatchMetrics);
export const getSentryErrorsSelector = () =>
  createSelector(selectDashboard, (state) => state.sentryErrors);
export const getPrometheusMetricsSelector = () =>
  createSelector(selectDashboard, (state) => state.prometheus);
