import React from "react";
import classnames from "classnames";
import { FieldItemType } from "@shared/interfaces";
import { ErrorMessage, Input } from "@shared/components";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface PasswordFieldItem extends RenderField {
  type: FieldItemType.PASSWORD;
  label: string;
  isShowPasswordIcon: boolean;
  disabled?: boolean;
  placeholder?: string;
}

export const GeneratePasswordField: GenerateRenderField<PasswordFieldItem> = (
  props
) => {
  const {
    formikProps: { errors, touched, handleBlur, handleChange, values },
    wrapperClass,
    name,
    placeholder,
    disabled,
    type,
    label,
    isShowPasswordIcon,
  } = props;

  return (
    <div
      className={classnames(wrapperClass, {
        error: errors[name] && touched[name],
      })}
      id={name}
    >
      <Input
        isShowPasswordIcon={isShowPasswordIcon}
        type={type}
        label={label}
        name={name}
        placeholder={placeholder}
        isDisabled={disabled}
        onChange={handleChange}
        onBlur={handleBlur}
        value={values[name]}
      />
      <ErrorMessage
        isTouched={!!touched[name]}
        error={errors[name]?.toString()}
      />
    </div>
  );
};
