import config from "../../config";

const api = {
  AUTH: {
    LOGIN: `${config.baseApiUrl}auth/login`,
    REGISTER: `${config.baseApiUrl}auth/register`,
    FORGOT_PASSWORD: `${config.baseApiUrl}auth/forgotPassword`,
    SET_PASSWORD: `${config.baseApiUrl}auth/setPassword`,
    REGISTRATION: `${config.baseApiUrl}auth/registration`,
    ACTIVATE: `${config.baseApiUrl}auth/activate`,
  },
  USER: {
    GET_USER_INFO: `${config.baseApiUrl}user`,
  },
  DASHBOARD: {
    GET_SENTRY_ERRORS: `${config.baseApiUrl}dashboard/sentry`,
    GET_API_STATUS: `${config.baseApiUrl}dashboard/api_status`,
    GET_COST_EXPLORER: `${config.baseApiUrl}dashboard/cost_explorer`,
    GET_CLOUDWATCH: `${config.baseApiUrl}dashboard/cloudwatch`,
    GET_PROMETHEUS: `${config.baseApiUrl}dashboard/prometheus`,
  },
};

export default api;
