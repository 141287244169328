import React, { useCallback } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Flex,
  IconButton,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  Menu,
  Avatar,
  Container,
} from "@chakra-ui/react";
import Logo from "@assets/images/logo.svg";
import { logout } from "@containers/Auth/store/actions";
import { BOX_SHADOW } from "@shared/constants";

const Header: React.FC = () => {
  const dispatch = useDispatch();
  const { onOpen, onClose } = useDisclosure();

  const logOut = useCallback(() => {
    dispatch(logout.request());
    onClose();
  }, [dispatch, onClose]);

  return (
    <Flex boxShadow={BOX_SHADOW} bgColor="white">
      <Container
        maxW="container.xl"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        padding="15px 0"
      >
        <Box className="logo__wrapper">
          <img width="60px" height="60px" src={Logo} alt="Logo" />
        </Box>
        <Menu placement="bottom-end">
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<Avatar size="sm" />}
            onClick={onOpen}
            variant="outline"
          />
          <MenuList>
            <MenuItem onClick={logOut}>Logout</MenuItem>
          </MenuList>
        </Menu>
      </Container>
    </Flex>
  );
};

export default Header;
