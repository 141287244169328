import { createSelector } from "reselect";

import { AppState } from "../interfaces";

const selectShared = (state: AppState) => state.shared;

export const getLoading = () =>
  createSelector(selectShared, (state) => state.loading);

export const getNotification = () =>
  createSelector(selectShared, (state) => state.notification);

export const getModal = () =>
  createSelector(selectShared, (state) => state.modal);

export const getOverlay = () =>
  createSelector(selectShared, (state) => state.overlay);

export const getNavigateURL = () =>
  createSelector(selectShared, (state) => state.navigateURL);

export const getLoadingSection = (sectionName: string) =>
  createSelector(selectShared, (state) => state.loadingSections[sectionName]);
