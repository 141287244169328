import React, { useMemo } from "react";
import classnames from "classnames";
import { FieldItemType } from "@shared/interfaces";

import { RenderField, GenerateRenderField } from "../../interfaces";

export interface BlockFieldItem extends RenderField {
  type: FieldItemType.BLOCK;
  content: React.ReactNode | string;
  placeholder?: string;
}

export const GenerateBlock: GenerateRenderField<BlockFieldItem> = (props) => {
  const {
    name,
    wrapperClass,
    content,
    placeholder,
    formikProps: { values },
  } = props;

  const value = useMemo(() => {
    return values[name];
  }, [values, name]);

  return (
    <div
      key={name}
      id={name}
      className={classnames("form-input-block", wrapperClass)}
    >
      {content ? <div className={`${name}-content`}>{content}</div> : null}
      {value ? (
        <div className={`${name}-value`}>{value}</div>
      ) : placeholder ? (
        <div className={`${name}-placeholder`}>{placeholder}</div>
      ) : null}
    </div>
  );
};
