import React, { useEffect, useState, useMemo, useCallback } from "react";
import classnames from "classnames";
import { useDispatch, useSelector } from "react-redux";
import { Select, Icon } from "@shared/components";
import { useDebounce } from "@shared/hooks";
import {
  OnChangeValue,
  MenuListProps,
  Props as SelectProps,
  MultiValueGenericProps,
} from "react-select";
import { getUniqueArray } from "@shared/utils";
import { AnyType, Option } from "@shared/interfaces";
import "./index.scss";
import { AnyAction } from "redux";

export interface AutocompleteMultiSelectProps extends Partial<SelectProps> {
  name: string;
  options: OnChangeValue<Option, true>;
  value: { label: string; value: string; object?: unknown }[];
  getData: (params: string, page?: number) => AnyAction;
  handleRemoveValue?: (id: string) => void;
  onLockedOptionClick?: (option: Option) => void;
  onOptionClick?: (option: Option) => void;
  placeholder?: string;
  className?: string;
  isDisabled?: boolean;
  menuWrapper?: (props: MenuListProps) => JSX.Element;
  multiValueContainer?: (props: MultiValueGenericProps) => JSX.Element;
  isLockedLogic?: boolean;
  availableOptions?: number[];

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prepareOptionFunction?: (element: any) => any;
  prepareBadgeFunction?: (label: string, object?: unknown) => string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectData: () => any;
  selectTotalCount?: () => AnyType;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (option: any) => void;
  hideValues?: boolean;
  hideSelectedOptions?: boolean;
  staticOptions?: OnChangeValue<Option, true>;
  lazyLoad?: boolean;

  highlightValues?: (string | number | null)[];
  invertHighlight?: boolean;
}

export function AutocompleteMultiSelect(props: AutocompleteMultiSelectProps) {
  const {
    selectData,
    getData,
    prepareOptionFunction,
    prepareBadgeFunction,
    value,
    handleRemoveValue,
    menuWrapper,
    isLockedLogic,
    availableOptions = [],
    hideValues,
    staticOptions,
    onOptionClick,
    onLockedOptionClick,
    noOptionsMessage,
    selectTotalCount,
    lazyLoad = true,
    highlightValues,
    invertHighlight,
    ...selectProps
  } = props;
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const suggestions: any = useSelector(selectData());
  const total: AnyType = useSelector(
    selectTotalCount ? selectTotalCount() : () => 0
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [page, setPage] = useState(0);
  const [options, setOptions] = useState(selectProps.options || []);
  const [inputValue, setInputValue] = useState("");

  const debouncedSearchQuery = useDebounce(searchQuery, 500);

  const handleOptionClick = useCallback(
    (isLockedOption: boolean, option: Option) => {
      onOptionClick?.(option);
      if (isLockedOption && onLockedOptionClick) {
        onLockedOptionClick(option);
      }
    },
    [onLockedOptionClick, onOptionClick]
  );

  const selectedValues = useMemo(() => {
    return value.map(({ value, label, object }, index) => {
      const isLocked = Boolean(
        isLockedLogic && !availableOptions.find((v) => v === Number(value))
      );
      const key = `${value}_${label}_${index}`;

      let isHighlight = highlightValues?.includes(value);

      if (invertHighlight) {
        isHighlight = !isHighlight;
      }

      const item = (
        <div
          key={key}
          onClick={() => handleOptionClick(isLocked, { value, label })}
          className={classnames("multi-autocomplete__value", {
            "multi-autocomplete__value--is-clickable": Boolean(
              onLockedOptionClick || onOptionClick
            ),
            "multi-autocomplete__value--is-locked": isLocked,
            "multi-autocomplete__value--is-highlight": isHighlight,
          })}
        >
          <div className="lock-icon">
            <Icon type="lock" />
          </div>
          <div>
            {prepareBadgeFunction ? prepareBadgeFunction(label, object) : label}
          </div>
          {!props.isDisabled && (
            <div
              className="multi-autocomplete__value-close"
              onClick={(e) => {
                e.stopPropagation();
                handleRemoveValue?.(value);
              }}
            >
              <Icon type="clear" />
            </div>
          )}
        </div>
      );

      return item;
    });
  }, [
    value,
    handleRemoveValue,
    isLockedLogic,
    availableOptions,
    props.isDisabled,
    handleOptionClick,
    onOptionClick,
    onLockedOptionClick,
    highlightValues,
    invertHighlight,
    prepareBadgeFunction,
  ]);

  useEffect(() => {
    if (prepareOptionFunction) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newSuggestions = suggestions.map((s: any) =>
        prepareOptionFunction(s)
      );
      const options: Option[] = staticOptions
        ? [...staticOptions, ...newSuggestions]
        : newSuggestions;
      setOptions(getUniqueArray(options, "value"));
    }
  }, [suggestions, prepareOptionFunction, staticOptions]);

  useEffect(() => {
    if (!props.isDisabled) {
      dispatch(getData(debouncedSearchQuery, page));
    }
  }, [debouncedSearchQuery, dispatch, getData, props.isDisabled, page]);

  const onMenuScrollToBottom = useCallback(() => {
    if (lazyLoad && total && suggestions.length < total) {
      setPage((prev) => prev + 1);
    }
  }, [lazyLoad, total, suggestions]);

  return (
    <div className="multi-autocomplete">
      {!props.isDisabled && (
        <Select
          {...selectProps}
          value={value}
          options={options}
          isMulti={true}
          onInputChange={(query, { action }) => {
            setSearchQuery(query);
            if (action !== "set-value" && action !== "menu-close") {
              setInputValue(query);
            }
          }}
          placeholder={selectProps.placeholder || "Start typing here"}
          noOptionsMessage={noOptionsMessage || (() => "No matches found")}
          menuWrapper={menuWrapper}
          onMenuScrollToBottom={onMenuScrollToBottom}
          isClearable={props.isClearable || false}
          blurInputOnSelect={false}
          inputValue={inputValue}
        />
      )}
      {!hideValues && (
        <div className="multi-autocomplete__values">{selectedValues}</div>
      )}
    </div>
  );
}

export default AutocompleteMultiSelect;
