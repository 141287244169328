import { actions } from "@shared/store";
import { AppState } from "@shared/interfaces";
import { put, select, takeEvery } from "redux-saga/effects";
import { AnyAction } from "redux";

function* watchLoaders(payload: AnyAction) {
  const state: AppState = yield select();
  const { loadingTypes } = state.shared;

  const startedSection = loadingTypes.find(({ startActions }) =>
    startActions.includes(payload.type)
  );
  const stoppedSection = loadingTypes.find(
    ({ stopActions }) => stopActions[payload.type]
  );

  if (startedSection) {
    yield put(
      actions.addLoadingSection({
        loadingSection: startedSection.name,
        requestName: payload.type,
      })
    );
  }
  if (stoppedSection) {
    yield put(
      actions.removeLoadingSection({
        loadingSection: stoppedSection.name,
        requestName: stoppedSection.stopActions[payload.type],
      })
    );
  }
}

function* sharedSagas() {
  yield takeEvery("*", watchLoaders);
}

export default sharedSagas;
