import React, { useEffect, useState, useMemo, useCallback } from "react";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { Select } from "@shared/components";
import { useDebounce } from "@shared/hooks";
import { AnyType } from "@shared/interfaces";
import { OnChangeValue, MenuListProps } from "react-select";

interface Option {
  label: string;
  value: string | number | null;
}

export interface AutocompleteSelectProps {
  name: string;
  options: OnChangeValue<Option, true>;
  value: number | string | null | undefined;
  filterValues?: string[];
  getData: (params: string, page?: number) => AnyAction;
  handleRemoveValue?: (id: string) => void;
  placeholder?: string;
  className?: string;
  isDisabled?: boolean;
  isTookOutSelects?: boolean;
  isMulti?: boolean;
  isClearable?: boolean;
  initialValue?: Option;
  noOptionsMessage?: string;
  isRequest?: boolean;
  menuWrapper?: (props: MenuListProps) => JSX.Element;
  onInputChange?: (value: string) => void;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  prepareOptionFunction?: (element: any) => any;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectData: () => any;
  selectTotalCount?: () => AnyType;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (option: any, originObject: unknown) => void;
  lazyLoad?: boolean;
  onChangePage?: (page: number) => void;
}

export function AutocompleteSelect(props: AutocompleteSelectProps) {
  const {
    selectData,
    getData,
    prepareOptionFunction,
    value,
    initialValue,
    menuWrapper,
    isRequest = true,
    onInputChange,
    onChange,
    lazyLoad = true,
    onChangePage,
    selectTotalCount,
    ...selectProps
  } = props;
  const dispatch = useDispatch();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const suggestions: any = useSelector(selectData());
  const total: AnyType = useSelector(
    selectTotalCount ? selectTotalCount() : () => 0
  );
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [page, setPage] = useState(0);
  const [staticOptions, setStaticOption] = useState<
    OnChangeValue<Option, true>
  >(selectProps.options);
  const [options, setOptions] = useState(selectProps.options || []);
  const debouncedSearchQuery = useDebounce(searchQuery, 500);
  const currentOptionValue = useMemo(() => {
    return options.find((option) => option.value === value) || null;
  }, [options, value]);

  useEffect(() => {
    if (
      currentOptionValue &&
      !staticOptions.find((option) => option.value === currentOptionValue.value)
    ) {
      setStaticOption([...staticOptions, currentOptionValue]);
    }
  }, [currentOptionValue, staticOptions]);

  useEffect(() => {
    if (prepareOptionFunction) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const newSuggestions = suggestions.map((s: any) =>
        prepareOptionFunction(s)
      );
      const resultOptions =
        !debouncedSearchQuery && staticOptions.length
          ? [
              ...staticOptions.filter(
                (o) => !newSuggestions.find((s: AnyType) => s.value === o.value)
              ),
              ...newSuggestions,
            ]
          : newSuggestions;
      setOptions(resultOptions);
    }
  }, [suggestions, prepareOptionFunction, debouncedSearchQuery, staticOptions]);

  useEffect(() => {
    if (initialValue && !currentOptionValue) {
      setOptions((prev) => [initialValue].concat(prev));
    }
  }, [initialValue, currentOptionValue]);

  useEffect(() => {
    if (isRequest && !props.isDisabled) {
      dispatch(getData(debouncedSearchQuery, page));
    }
  }, [
    debouncedSearchQuery,
    dispatch,
    getData,
    isRequest,
    props.isDisabled,
    page,
  ]);

  const changeInput = useCallback(
    (value: string) => {
      setSearchQuery(value);
      setPage(0);
      if (onInputChange) {
        onInputChange(value);
      }
    },
    [setSearchQuery, onInputChange]
  );

  const handleChange = useCallback(
    (value: unknown) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const originObject = suggestions?.find(
        (obj: any) => Number(obj.id) === Number(value)
      );
      onChange?.(value, originObject);
    },
    [onChange, suggestions]
  );

  const onMenuScrollToBottom = useCallback(() => {
    if (lazyLoad && total && suggestions.length < total) {
      setPage((prev) => prev + 1);
    }
  }, [lazyLoad, total, suggestions]);

  useEffect(() => {
    if (onChangePage && Boolean(page)) {
      onChangePage(page);
    }
  }, [onChangePage, page]);

  return (
    <Select
      {...selectProps}
      onChange={handleChange}
      value={currentOptionValue}
      options={options}
      menuWrapper={menuWrapper}
      onInputChange={changeInput}
      onMenuScrollToBottom={onMenuScrollToBottom}
      placeholder={selectProps.placeholder || "Start typing here"}
      noOptionsMessage={() =>
        selectProps.noOptionsMessage || "No matches found"
      }
    />
  );
}

export default AutocompleteSelect;
