import { FC, ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { authentificated } from "@containers/Auth/store/selectors";

import { NamesOfParentRoutes } from "../constants";

interface GuestGuardProps {
  children: ReactNode;
}

const GuestGuard: FC<GuestGuardProps> = ({ children }) => {
  const isAuthenticated = useSelector(authentificated());

  return isAuthenticated ? (
    <Navigate to={NamesOfParentRoutes.DASHBOARD} />
  ) : (
    <>{children}</>
  );
};

export default GuestGuard;
