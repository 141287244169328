export enum DashboardActionTypes {
  GET_SENTRY_ERRORS = "@@DASHBOARD/GET_SENTRY_ERRORS",
  GET_SENTRY_ERRORS_SUCCESS = "@@DASHBOARD/GET_SENTRY_ERRORS_SUCCESS",
  GET_SENTRY_ERRORS_FAILURE = "@@DASHBOARD/GET_SENTRY_ERRORS_FAILURE",

  GET_API_STATUS = "@@DASHBOARD/GET_API_STATUS",
  GET_API_STATUS_SUCCESS = "@@DASHBOARD/GET_API_STATUS_SUCCESS",
  GET_API_STATUS_FAILURE = "@@DASHBOARD/GET_API_STATUS_FAILURE",

  GET_COST_EXPLORER = "@@DASHBOARD/GET_COST_EXPLORER",
  GET_COST_EXPLORER_SUCCESS = "@@DASHBOARD/GET_COST_EXPLORER_SUCCESS",
  GET_COST_EXPLORER_FAILURE = "@@DASHBOARD/GET_COST_EXPLORER_FAILURE",

  GET_CLOUDWATCH = "@@DASHBOARD/GET_CLOUDWATCH",
  GET_CLOUDWATCH_SUCCESS = "@@DASHBOARD/GET_CLOUDWATCH_SUCCESS",
  GET_CLOUDWATCH_FAILURE = "@@DASHBOARD/GET_CLOUDWATCH_FAILURE",

  GET_PROMETHEUS = "@@DASHBOARD/GET_PROMETHEUS",
  GET_PROMETHEUS_SUCCESS = "@@DASHBOARD/GET_PROMETHEUS_SUCCESS",
  GET_PROMETHEUS_FAILURE = "@@DASHBOARD/GET_PROMETHEUS_FAILURE",
}
