import { createAsyncAction } from "typesafe-actions";
import { BaseResponse } from "@shared/interfaces";
import { User } from "@shared/models";

import { AuthActionTypes } from "./constants";
import {
  LoginShape,
  RestoreShape,
  ChangePasswordPayloadShape,
  ActivatePayloadShape,
  RegistrationPayloadShape,
} from "../interface";

export const login = createAsyncAction(
  AuthActionTypes.LOGIN,
  AuthActionTypes.LOGIN_SUCCESS,
  AuthActionTypes.LOGIN_FAILURE
)<LoginShape, undefined, Error>();

export const logout = createAsyncAction(
  AuthActionTypes.LOGOUT,
  AuthActionTypes.LOGOUT_SUCCESS,
  AuthActionTypes.LOGOUT_FAILURE
)<undefined, undefined, Error>();

export const forgotPassword = createAsyncAction(
  AuthActionTypes.FORGOT_PASSWORD,
  AuthActionTypes.FORGOT_PASSWORD_SUCCESS,
  AuthActionTypes.FORGOT_PASSWORD_FAILURE
)<RestoreShape, BaseResponse, Error>();

export const setPassword = createAsyncAction(
  AuthActionTypes.SET_PASSWORD,
  AuthActionTypes.SET_PASSWORD_SUCCESS,
  AuthActionTypes.SET_PASSWORD_FAILURE
)<ChangePasswordPayloadShape, undefined, Error>();

export const registration = createAsyncAction(
  AuthActionTypes.REGISTRATION,
  AuthActionTypes.REGISTRATION_SUCCESS,
  AuthActionTypes.REGISTRATION_FAILURE
)<RegistrationPayloadShape, { token: string }, Error>();

export const activateAccount = createAsyncAction(
  AuthActionTypes.ACTIVATE_ACCOUNT,
  AuthActionTypes.ACTIVATE_ACCOUNT_SUCCESS,
  AuthActionTypes.ACTIVATE_ACCOUNT_FAILURE
)<ActivatePayloadShape, Partial<User> | null, Error>();
