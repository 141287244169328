import { call, put, takeLatest } from "redux-saga/effects";
import { startLoading, stopLoading } from "@shared/store/actions";
import {
  ApiStatus,
  CloudWatchMetric,
  CostExplorer,
  Prometheus,
  SentryError,
} from "@containers/Dashboard/interface";

import api from "../api";

import { actions } from "./index";

function* getSentryErrorsSaga(): Generator {
  try {
    yield put(startLoading());
    const data = (yield call(api.getSentryErrors) as unknown) as SentryError[];
    yield put(stopLoading());
    yield put(actions.getSentryErrors.success(data));
  } catch (error) {
    yield put(actions.getSentryErrors.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getCloudWatchMetricSaga(
  action: ReturnType<typeof actions.getCloudWatchMetric.request>
): Generator {
  try {
    yield put(startLoading());
    const data = (yield call(
      api.getCloudWatch,
      action.payload
    ) as unknown) as CloudWatchMetric[];
    yield put(stopLoading());
    yield put(actions.getCloudWatchMetric.success(data));
  } catch (error) {
    yield put(actions.getCloudWatchMetric.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getApiStatusSaga(): Generator {
  try {
    yield put(startLoading());
    const data = (yield call(api.getApiStatus) as unknown) as ApiStatus[];
    yield put(stopLoading());
    yield put(actions.getApiStatus.success(data));
  } catch (error) {
    yield put(actions.getApiStatus.failure(error as Error));
    yield put(stopLoading());
  }
}

function* getCostExplorerSaga(): Generator {
  try {
    yield put(startLoading());
    const data = (yield call(api.getCostExplorer) as unknown) as CostExplorer;
    yield put(stopLoading());
    yield put(actions.getCostExplorer.success(data));
  } catch (error) {
    yield put(actions.getCostExplorer.failure(error as Error));
    yield put(stopLoading());
  }
}
function* getPrometheusSaga(
  action: ReturnType<typeof actions.getPrometheusMetric.request>
): Generator {
  try {
    yield put(startLoading());
    const data = (yield call(
      api.getPrometheus,
      action.payload
    ) as unknown) as Prometheus;
    yield put(stopLoading());
    yield put(actions.getPrometheusMetric.success(data));
  } catch (error) {
    yield put(actions.getPrometheusMetric.failure(error as Error));
    yield put(stopLoading());
  }
}

function* dashboardSagas() {
  yield takeLatest(actions.getSentryErrors.request, getSentryErrorsSaga);
  yield takeLatest(actions.getApiStatus.request, getApiStatusSaga);
  yield takeLatest(
    actions.getCloudWatchMetric.request,
    getCloudWatchMetricSaga
  );
  yield takeLatest(actions.getCostExplorer.request, getCostExplorerSaga);
  yield takeLatest(actions.getPrometheusMetric.request, getPrometheusSaga);
}
export default dashboardSagas;
