import { call, put, takeLatest } from "redux-saga/effects";
import { tokenHandler } from "@shared/utils";
import { navigate, startLoading, stopLoading } from "@shared/store/actions";
import { ActionWithPayload, BaseResponse } from "@shared/interfaces";
import { NameOfRoutes } from "@shared/constants";
import { User } from "@shared/models";

import {
  logout,
  login,
  forgotPassword,
  setPassword,
  activateAccount,
  registration,
} from "./actions";
import api from "../api";
import {
  RestoreShape,
  LoginShape,
  ChangePasswordPayloadShape,
  ActivatePayloadShape,
  RegistrationPayloadShape,
} from "../interface";

function* loginUser(token: string) {
  yield tokenHandler.set(token);
  yield put(navigate("/"));
  yield put(login.success());
}

function* clearLocalStorage() {
  yield localStorage.clear();
}

function* logoutSaga() {
  yield clearLocalStorage();
  yield put(logout.success());
  yield put(navigate(NameOfRoutes.AUTH_LOGIN));
}

function* loginSaga({ payload }: ActionWithPayload<LoginShape>) {
  try {
    yield put(startLoading());
    yield clearLocalStorage();

    const { token }: { token: string } = yield call(api.login, payload);
    yield put(stopLoading());

    yield loginUser(token);
  } catch (error) {
    yield put(login.failure(error as Error));
    yield put(stopLoading());
  }
}

function* forgotPasswordSaga({ payload }: ActionWithPayload<RestoreShape>) {
  try {
    yield put(startLoading());
    const response: BaseResponse = yield call(api.forgotPassword, payload);
    yield put(stopLoading());
    yield put(forgotPassword.success(response));
  } catch (error) {
    yield put(forgotPassword.failure(error as Error));
    yield put(stopLoading());
  }
}

function* setPasswordSaga({
  payload,
}: ActionWithPayload<ChangePasswordPayloadShape>) {
  try {
    yield put(startLoading());
    const { token }: { token: string } = yield call(api.setPassword, payload);
    yield put(stopLoading());
    yield loginUser(token);
  } catch (error) {
    yield put(forgotPassword.failure(error as Error));
    yield put(stopLoading());
  }
}

function* activateSaga({ payload }: ActionWithPayload<ActivatePayloadShape>) {
  try {
    yield put(startLoading());
    const { token, user }: { token: string; user: Partial<User> } = yield call(
      api.activate,
      payload
    );
    yield put(stopLoading());

    if (user) {
      yield put(activateAccount.success(user));
    }

    if (token) {
      yield loginUser(token);
    }
  } catch (error) {
    yield put(navigate(NameOfRoutes.AUTH));
    yield put(activateAccount.failure(error as Error));
    yield put(stopLoading());
  }
}

function* registrationSaga({
  payload,
}: ActionWithPayload<RegistrationPayloadShape>) {
  try {
    yield put(startLoading());
    const { token }: { token: string } = yield call(api.registration, payload);
    yield put(stopLoading());
    yield loginUser(token);
  } catch (error) {
    yield put(activateAccount.failure(error as Error));
    yield put(stopLoading());
  }
}

function* authSagas() {
  yield takeLatest(logout.request, logoutSaga);
  yield takeLatest(login.request, loginSaga);
  yield takeLatest(forgotPassword.request, forgotPasswordSaga);
  yield takeLatest(setPassword.request, setPasswordSaga);
  yield takeLatest(activateAccount.request, activateSaga);
  yield takeLatest(registration.request, registrationSaga);
}

export default authSagas;
