import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";

const api = {
  getCostExplorer: () =>
    request(METHODS.GET, API.DASHBOARD.GET_COST_EXPLORER)(),
  getApiStatus: () => request(METHODS.GET, API.DASHBOARD.GET_API_STATUS)(),
  getCloudWatch: (payload: { last_hour: boolean }) =>
    request(METHODS.GET, API.DASHBOARD.GET_CLOUDWATCH)(null, {
      params: payload,
    }),
  getSentryErrors: () =>
    request(METHODS.GET, API.DASHBOARD.GET_SENTRY_ERRORS)(),
  getPrometheus: (payload: { dimension: string }) =>
    request(METHODS.GET, API.DASHBOARD.GET_PROMETHEUS)(null, {
      params: payload,
    }),
};

export default api;
