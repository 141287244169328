import { AnyType } from "@shared/interfaces";
import moment from "moment";

export const debounce = <F extends (...args: AnyType) => AnyType>(
  func: F,
  waitFor = 500
) => {
  let timeout: ReturnType<typeof setTimeout> | null = null;
  const debounced = (...args: AnyType) => {
    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => func(...args), waitFor);
  };

  return debounced as (...args: Parameters<F>) => ReturnType<F>;
};

export function getUniqueArray<T>(a: T[], property: keyof T) {
  return a.filter(
    (item, pos, self) =>
      self.findIndex((v) => v[property] === item[property]) === pos
  );
}

export const validateFile = (
  file: File | null,
  options: { maxSize?: number }
): string => {
  if (file && options.maxSize && file.size > options.maxSize) {
    return `Max size of the file should be ${options.maxSize / 1000000} MB`;
  }

  return "";
};

export const getUSFormattedDate = (
  date: Date | string | undefined | null,
  timeZone?: string
) => {
  return date
    ? new Date(date).toLocaleString("en-US", { timeZone }).split(",")[0]
    : "-";
};

export const getUSFormattedDateLong = (
  date: Date | string | undefined | null,
  timeZone?: string
) => {
  return date
    ? new Date(date).toLocaleString("en-US", {
        year: "numeric",
        month: "long",
        day: "numeric",
        timeZone,
      })
    : "-";
};

export const getUSFormatedTime = (date: Date | string, timeZone?: string) => {
  const tzDate = new Date(date).toLocaleString("en-US", { timeZone });

  return date
    ? moment(tzDate, "MM/DD/YYYY, hh:mm:ss A").locale("en-US").format("LT")
    : "-";
};

export const getUSFormatedDateTime = (
  date: Date | string,
  timeZone?: string
) => {
  return date
    ? `${getUSFormattedDate(date, timeZone)} ${getUSFormatedTime(
        date,
        timeZone
      )}`
    : "-";
};

export function formatPrice(price: string) {
  // Parse the price as float
  const parsedPrice = parseFloat(price);

  // Use JavaScript's built-in Intl object to format as USD
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(parsedPrice);
}

export function formatDateWithTime(date: string | Date) {
  return new Intl.DateTimeFormat("en-GB", {
    year: "numeric",
    month: "long",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  }).format(new Date(date));
}
