import { ActionTypes } from "@shared/interfaces";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  actions as sharedActions,
  selectors as sharedSelectors,
} from "../store";
import useDebounce from "./DebounceHook";

interface UseLoaderProps {
  name: string;
  actionTypes: ActionTypes[];
}

interface LoadingType {
  name: string;
  startActions: string[];
  stopActions: Record<string, string>;
}

const createLoadingType = (
  name: string,
  actionTypes: ActionTypes[]
): LoadingType => {
  return actionTypes.reduce<LoadingType>(
    (acc, actionType) => {
      const requestType = actionType.request().type;
      const successType = actionType.success().type;
      const errorType = actionType.failure().type;

      acc.startActions.push(requestType);
      acc.stopActions[successType] = requestType;
      acc.stopActions[errorType] = requestType;

      return acc;
    },
    {
      name,
      startActions: [],
      stopActions: {},
    }
  );
};

export const useLoader = ({ name, actionTypes }: UseLoaderProps) => {
  const dispatch = useDispatch();
  const loadingSection = useSelector(sharedSelectors.getLoadingSection(name));

  const isLoading = useMemo(() => {
    return !!loadingSection && !!loadingSection.length;
  }, [loadingSection]);

  const isLoadingDebounced = useDebounce(isLoading, 750);

  useEffect(() => {
    if (name && actionTypes) {
      const loadingType = createLoadingType(name, actionTypes);
      dispatch(sharedActions.addLoadingType(loadingType));
    }

    return () => {
      dispatch(sharedActions.removeLoadingType(name));
    };
  }, [name, actionTypes, dispatch]);

  return {
    isLoading,
    isLoadingDebounced: isLoading || isLoadingDebounced,
    loaderName: name,
  };
};
