import React, { useCallback } from "react";
import classnames from "classnames";
import { AutocompleteSelect, ErrorMessage } from "@shared/components";

import {
  AutocompleteSelectField,
  GenerateRenderField,
  Handlers,
} from "../../interfaces";

export const GenerateSelectSearch: GenerateRenderField<
  AutocompleteSelectField & Handlers
> = (props) => {
  const {
    formikProps: { errors, touched, setFieldValue, values, setFieldTouched },
    handlers,
    wrapperClass,
    disabled,
    label,
    name,
    options,
    placeholder,
    initialValue,
    onFieldChange,
    menuWrapper,
    isClearable,
  } = props;

  const { getData, selectData, prepareOptionFunction, selectTotalCount } =
    (handlers || {})[name];
  const handleChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (option: any, originObject?: unknown) => {
      setFieldTouched(name, true);
      setFieldValue && setFieldValue(name, option);
      onFieldChange?.(option, originObject);
    },
    [name, setFieldTouched, setFieldValue, onFieldChange]
  );

  const handleRemoveValue = useCallback(
    (id: string) => {
      setFieldValue &&
        setFieldValue(
          name,
          (values[name] || []).filter(
            ({ value }: { label: string; value: string }) => value !== id
          )
        );
      setFieldTouched(name, true);
    },
    [setFieldTouched, name, values, setFieldValue]
  );

  return (
    <div
      className={classnames("render-select", wrapperClass, {
        error: errors[name] && touched[name],
      })}
      key={name}
      id={name}
    >
      {label && <label className={classnames({ disabled })}>{label}</label>}
      <div className={classnames("select", { disabled })}>
        <AutocompleteSelect
          options={
            options.map((o) => ({
              label: o.label,
              value: String(o.value || ""),
            })) || []
          }
          name={name}
          placeholder={placeholder}
          isDisabled={disabled}
          getData={getData}
          selectData={selectData}
          selectTotalCount={selectTotalCount}
          onChange={handleChange}
          value={values[name]}
          handleRemoveValue={handleRemoveValue}
          prepareOptionFunction={prepareOptionFunction}
          initialValue={initialValue}
          menuWrapper={menuWrapper}
          isClearable={isClearable}
        />
      </div>
      <ErrorMessage
        isTouched={!!touched[name]}
        error={errors[name]?.toString()}
      />
    </div>
  );
};
