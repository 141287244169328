import React, { FC } from "react";
import classnames from "classnames";
import { FormikValues } from "formik";

import "./index.scss";

interface TextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
  className?: string;
  label?: string;
  setFieldValue?: () => void;
  values?: FormikValues;
  showCharacterCounter?: boolean;
  characterCounterLimit?: number;
}

const Textarea: FC<TextareaProps> = (props) => {
  const {
    className,
    showCharacterCounter,
    characterCounterLimit,
    ...inputProps
  } = props;
  return (
    <div className={classnames("textarea", className)}>
      {props.label && (
        <div className="textarea__details">
          <label
            className={classnames("textarea__label", {
              "textarea__label--disable": props.disabled,
            })}
          >
            {props.label}
          </label>
          {showCharacterCounter && (
            <span className="textarea__counter">
              {inputProps.value ? String(inputProps.value).length : 0}
              {characterCounterLimit && `/${characterCounterLimit}`}
            </span>
          )}
        </div>
      )}
      <textarea {...inputProps} />
    </div>
  );
};
export default Textarea;
