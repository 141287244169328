import { produce } from "immer";
import { ActionType, createReducer } from "typesafe-actions";

import { DashboardStateType } from "../interface";
import * as actions from "./actions";

type Action = ActionType<typeof actions>;

export const initialState: DashboardStateType = {
  sentryErrors: [],
  cloudWatchMetrics: [],
  apiStatus: [],
  costExplorer: null,
  prometheus: null,
};

const reducer = createReducer<DashboardStateType, Action>(initialState)
  .handleAction(actions.getApiStatus.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.apiStatus = action.payload;
    })
  )
  .handleAction(actions.getCostExplorer.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.costExplorer = action.payload;
    })
  )
  .handleAction(actions.getCloudWatchMetric.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.cloudWatchMetrics = action.payload;
    })
  )
  .handleAction(actions.getSentryErrors.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.sentryErrors = action.payload;
    })
  )
  .handleAction(actions.getPrometheusMetric.success, (state, action) =>
    produce(state, (nextState) => {
      nextState.prometheus = action.payload;
    })
  );

export { reducer as DashboardReducer };
