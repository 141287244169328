import { all, fork } from "redux-saga/effects";
import { saga as sharedSaga } from "@shared/store";

import { saga as authSaga } from "../containers/Auth/store";
import { saga as dashboardSaga } from "../containers/Dashboard/store";

const allSagas = [authSaga, dashboardSaga, sharedSaga];

export default function* appSagas() {
  yield all(allSagas.map(fork));
}
