import { request } from "@shared/utils";
import { API, METHODS } from "@shared/constants";

import {
  RestoreShape,
  LoginShape,
  ChangePasswordPayloadShape,
  RegistrationPayloadShape,
  ActivatePayloadShape,
} from "../interface";

const api = {
  login: (payload: LoginShape) =>
    request(METHODS.POST, API.AUTH.LOGIN)(payload),
  forgotPassword: (payload: RestoreShape) =>
    request(METHODS.POST, API.AUTH.FORGOT_PASSWORD)(payload),
  setPassword: (payload: ChangePasswordPayloadShape) =>
    request(METHODS.POST, API.AUTH.SET_PASSWORD)(payload),
  registration: (payload: RegistrationPayloadShape) =>
    request(METHODS.POST, API.AUTH.REGISTRATION)(payload),
  activate: (payload: ActivatePayloadShape) =>
    request(METHODS.POST, API.AUTH.ACTIVATE)(payload),
};

export default api;
