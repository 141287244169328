import React, { useMemo } from "react";
import classnames from "classnames";
import { Select } from "@shared/components";
import {
  OnChangeValue,
  Props as SelectProps,
  MultiValueGenericProps,
} from "react-select";

import "./index.scss";

export interface Option {
  label: string;
  value: string | number | null;
}

export interface MultiSelectProps extends Partial<SelectProps> {
  name: string;
  options: OnChangeValue<Option, true>;
  value: { label: string; value: string }[];
  placeholder?: string;
  className?: string;
  isDisabled?: boolean;
  handleRemoveValue?: (id: string) => void;
  multiValueContainer?: (props: MultiValueGenericProps) => JSX.Element;
  onChange: (option: unknown) => void;
}

export function MultiSelect(props: MultiSelectProps) {
  const { value, handleRemoveValue, noOptionsMessage, ...selectProps } = props;

  const selectedValues = useMemo(() => {
    return value.map(({ value, label }) => {
      return (
        <div key={value} className={classnames("multi_select_value_item")}>
          <div>{label}</div>
          {!props.isDisabled && (
            <div
              className="multi_select_value_close"
              onClick={() => handleRemoveValue && handleRemoveValue(value)}
            >
              X
            </div>
          )}
        </div>
      );
    });
  }, [value, handleRemoveValue, props.isDisabled]);

  return (
    <div className="autocomplete-select-wrapper">
      {!props.isDisabled && (
        <Select
          {...selectProps}
          value={value}
          isMulti={true}
          placeholder={selectProps.placeholder || "Start typing here"}
          noOptionsMessage={noOptionsMessage || (() => "No matches found")}
          isClearable={props.isClearable || false}
          blurInputOnSelect={false}
        />
      )}
      <div className="multi_select_values_wrapper">{selectedValues}</div>
    </div>
  );
}

export default MultiSelect;
