import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";

import configureStore from "./store";
import App from "./containers/App/App";
import * as serviceWorker from "./serviceWorker";

import "@assets/styles/index.scss";

const { store } = configureStore();

if (process.env.REACT_APP_ENV === "development") {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  whyDidYouRender(React, { trackHooks: true, trackAllPureComponents: true });
}

const styles = {
  colors: {
    brand: {
      "900": "#92C3E2", // Brighter
      "800": "#82B1D6",
      "700": "#729FCA",
      "600": "#638DBE",
      "500": "#6C9BC0", // Original color
      "400": "#5E889A", // Dimmer
      "300": "#507574",
      "200": "#42624E",
      "100": "#344F28",
      black: "#232428",
      grey: "#757F91",
      blue: "#5776AA",
      green: "#17C55C",
    },
    grey: {
      "100": "#CFD5DF",
    },
  },
  config: {
    initialColorMode: "light",
    useSystemColorMode: false,
  },
  fonts: {
    heading: "Red Hat Display, sans-serif",
    body: "Roboto, sans-serif",
  },
  styles: {
    global: {
      html: {
        height: "full",
      },
      body: {
        bgColor: "#F8F9FB",
        color: "brand.black",
        fontSize: "14px",
        height: "full",
      },
    },
  },
  components: {
    Button: {
      variants: {
        ghost: {
          color: "brand.blue",
          _active: {
            backgroundColor: "transparent",
          },
        },
        solid: {
          backgroundColor: "brand.blue",
          color: "white",
          fontWeight: 400,
          borderRadius: "lg",
          _hover: {
            backgroundColor: "brand.blue",
          },
          _active: {
            backgroundColor: "brand.blue",
          },
        },
      },
    },
    Divider: {
      baseStyle: {
        borderColor: "grey.100",
      },
    },
    Table: {
      variants: {
        simple: {
          thead: {
            tr: {
              th: {
                fontFamily: "heading",
                fontSize: "12px",
                fontWeight: 600,
                letterSpacing: "1.68px",
                color: "brand.grey",
                border: "none",
                px: 0,
                py: 2,
              },
            },
          },
          tbody: {
            tr: {
              td: {
                border: "none",
                px: 0,
                py: 2,
              },
            },
          },
        },
      },
    },
    Tag: {
      baseStyle: {
        container: {
          bgColor: "#E2E8F2",
          lineHeight: "20px",
          p: "6px 10px",
          fontWeight: 400,
          borderRadius: "lg",
        },
      },
    },
  },
};

const theme = extendTheme(styles);

const container = document.getElementById("root");

if (container) {
  const root = createRoot(container);
  root.render(
    <BrowserRouter>
      <Provider store={store}>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </Provider>
    </BrowserRouter>
  );
}
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
