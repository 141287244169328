import { createAsyncAction } from "typesafe-actions";
import {
  SentryError,
  ApiStatus,
  CostExplorer,
  CloudWatchMetric,
  Prometheus,
} from "@containers/Dashboard/interface";
import { DashboardActionTypes } from "@containers/Dashboard/store/constants";

export const getSentryErrors = createAsyncAction(
  DashboardActionTypes.GET_SENTRY_ERRORS,
  DashboardActionTypes.GET_SENTRY_ERRORS_SUCCESS,
  DashboardActionTypes.GET_SENTRY_ERRORS_FAILURE
)<void, SentryError[], Error>();

export const getApiStatus = createAsyncAction(
  DashboardActionTypes.GET_API_STATUS,
  DashboardActionTypes.GET_API_STATUS_SUCCESS,
  DashboardActionTypes.GET_API_STATUS_FAILURE
)<void, ApiStatus[], Error>();

export const getCostExplorer = createAsyncAction(
  DashboardActionTypes.GET_COST_EXPLORER,
  DashboardActionTypes.GET_COST_EXPLORER_SUCCESS,
  DashboardActionTypes.GET_COST_EXPLORER_FAILURE
)<void, CostExplorer, Error>();

export const getCloudWatchMetric = createAsyncAction(
  DashboardActionTypes.GET_CLOUDWATCH,
  DashboardActionTypes.GET_CLOUDWATCH_SUCCESS,
  DashboardActionTypes.GET_CLOUDWATCH_FAILURE
)<{ last_hour: boolean }, CloudWatchMetric[], Error>();

export const getPrometheusMetric = createAsyncAction(
  DashboardActionTypes.GET_PROMETHEUS,
  DashboardActionTypes.GET_PROMETHEUS_SUCCESS,
  DashboardActionTypes.GET_PROMETHEUS_FAILURE
)<{ dimension: string }, Prometheus, Error>();
